import { Controller } from "stimulus"
//import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ["output", "days"]
  connect() {
    this.outputTarget.textContent = 'How long have you been in quarantine?'
  }
  calculate() {
    const element = this.daysTarget
    const days = element.value
    const lockdownDays = 10
    let daysLeft = lockdownDays - days

    if (daysLeft < 1) {
      alert('You are free!')
    }
    else {
      alert(`Amount of days you need to stay on quarantine: ${daysLeft}`)
    }
  }
}

// add this to view for testing

// <div data-controller="hello" class="my-3 bg-warning">
//   <div class="p-3">
//     <h3>Stimulus Controller Test</h3>
//     <p data-target="hello.output"></p>
//     <input data-target="hello.days" type="number">
//     <button data-action="click->hello#calculate">Calculate</button>
//     <p class="small"><em>If this is working correctly then edits to th text blurb on (this.outputTarget.textContent) on app/javascripts/controllers/hello_controller.js will propogate on page refresh.</p></em>
//   </div>
// </div>
